import { Fade } from "react-awesome-reveal";

export const Team = (props) => {
  return (
    <div id='team' className='text-center'>
      <div className='container'>
        <div className='col-md-8 col-md-offset-2 section-title'>
          <h2>Meet the Team</h2>
          <p>
          Meet the dedicated team of human beings behind the Xenos project!
          </p>
        </div>
        <div id='row'>
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.name}-${i}`} className='col-md-3 col-sm-6 team'>
                  <div className='thumbnail'>
                    {' '}
                    <Fade>
                        <img src={d.img} alt='...' className='team-img' />
                          <div className='caption'>
                            <h4>{d.name}</h4>
                            {d.job}
                            <div id='description'>
                               <p>{d.description}</p>
                            </div>
                        </div>
                      </Fade>
                    </div>
                </div>
              ))
            : 'loading'}
        </div>
      </div>
    </div>
  )
}
