import { Fade } from "react-awesome-reveal";

export const XenosImages = (props) => {
  return (
    <div id='xenos_images' className='text-center'>
      <div className='container'>
      <h2>Meet the Xenos!</h2>
        <div id='row'>
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.name}-${i}`} className='col-md-4 col-sm-6 xenos_images'>
                  <Fade>
                  <div className='thumbnail'>
                    {' '}
                    <img src={d.img} alt='...' className='xenos-img' />
                  </div>
                  </Fade>
                </div>
              ))
            : 'loading'}
        </div>
      </div>
    </div>
  )
}
