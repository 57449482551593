export const Navigation = (props) => {
  return (
    <nav id='menu' className='navbar navbar-default navbar-fixed-top'>
      <div className='container'>
        <div className='navbar-header'>
          <button
            type='button'
            className='navbar-toggle collapsed'
            data-toggle='collapse'
            data-target='#bs-example-navbar-collapse-1'
          >
            {' '}
            <span className='sr-only'>Toggle navigation</span>{' '}
            <span className='icon-bar'></span>{' '}
            <span className='icon-bar'></span>{' '}
            <span className='icon-bar'></span>{' '}
          </button>
          {/*<a className='navbar-brand page-scroll' href='#page-top'>
            React Landing Page
          </a>{' '}*/}
        </div>

        <div
          className='collapse navbar-collapse'
          id='bs-example-navbar-collapse-1'
        >
          <ul className='nav navbar-nav navbar-right'>
          <li>
              <a href='#header' className='page-scroll-button'>
                  Mint
              </a>
            </li>
            <li>
              <a href='#story' className='page-scroll'>
                Mission
              </a>
            </li>
            <li>
              <a href='#xenos_images' className='page-scroll'>
                Art
              </a>
            </li>
            <li>
              <a href='#school' className='page-scroll'>
                School
              </a>
            </li>
            <li>
              <a href='#details' className='page-scroll'>
                F.A.Q.
              </a>
            </li>
            <li>
              <a href='#team' className='page-scroll'>
                Team
              </a>
            </li>
            <li>
              <a href='#contact' className='page-scroll'>
                Contact
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  )
}
