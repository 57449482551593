export const Timeline = (props) => {
  return (
    <div id='timeline' className='text-center'>
      <div className='container'>
          <h2>Timeline</h2>
          <p>
          The first collection will launch soon, with the following milestones:
          </p>
              <div id='row'>
                <div className='col-md-6 col-sm-6 timeline'>
                  <div className='thumbnail'>
                    {' '}
                    <img src="img/purple_hex.svg" alt='...' className='timeline-img' />
                  </div>
                </div>
                <div className='col-md-6 col-sm-6 timeline-text'>
                   <p>After the first 833 Xenos find their new homes, 25% of the funds raised will be donated to the school.</p>
                </div>
              </div>
              <div id='row'>
              <div className='col-md-6 col-sm-6 timeline'>
                  <div className='thumbnail'>
                    {' '}
                    <img src="img/orange_hex.svg" alt='...' className='timeline-img' />
                  </div>
                </div>
                <div className='col-md-6 col-sm-6 timeline-text'>
                  <p>With 1,667 Xenos sold, we are truly on our way! Another 25% of the funds raised will be set aside to support the school. By then, we expect to be able to share details of how the funds are being deployed at the school.</p>
                </div>
              </div>
              <div id='row'>
              <div className='col-md-6 col-sm-6 timeline'>
                  <div className='thumbnail'>
                    {' '}
                    <img src="img/green_hex.svg" alt='...' className='timeline-img' />
                  </div>
                </div>
                <div className='col-md-6 col-sm-6 timeline-text'>
                  <p>At this milestone, 2,500 Xenos will have found a way into your hearts! Time to pop a bottle of champagne! While we raise a glass, another 25% of the funds will be swiftly directed to our school in Uganda. At this point we will begin to work with the community to find the next school for the following collection.</p>
                </div>
              </div>
              <div id='row'>
              <div className='col-md-6 col-sm-6 timeline'>
                  <div className='thumbnail'>
                    {' '}
                    <img src="img/blue_hex.svg" alt='...' className='timeline-img' />
                  </div>
                </div>
                <div className='col-md-6 col-sm-6 timeline-text'>
                  <p>Woo hoo! All the Xenos found a home, making this a monumental day! Without missing a beat, we'll send the final 25% of the funds to our school. The team will work closely with them over the following months to ensure the Needs Study is being met and that we've made a meaningful impact. But our support to the school doesn't end here — a percentage of the royalty of each secondary sale will also be gifted to the school.</p>
                </div>
              </div>
      </div>
    </div>
  )
}
