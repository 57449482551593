import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { brands } from '@fortawesome/fontawesome-svg-core/import.macro'

export const Contact = (props) => {
  return (
      <div id='contact'>
        <div className='container'>
          <div className='col-md-12'>
            <div className='row'>
              <div className='social'>
                <ul>
                <li>
                    <a href={props.data ? props.data.twitter : '/'} target="_blank" rel="noreferrer">
                        <FontAwesomeIcon icon={brands('twitter')} className='contactButton' />
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.discord : '/'} target="_blank" rel="noreferrer">
                      <FontAwesomeIcon icon={brands('discord')} className='contactButton' />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
  )
}
