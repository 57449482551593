export const accordionData = [
    {
      heading: "A Great Discovery - Origin of Xenos ",
      content: [
        "On a hot and muggy afternoon, a group of children walking home from school noticed a meteor shower falling from the sky. As they got closer, they could see that it was not only meteorites but also hundreds of small colored spaceships! As they pushed through the dense foliage, they emerged into a clear landing and were amazed to discover thousands of small creatures emerging from their crafts. Extraterrestrial contact was made!",<br key="1"/>,<br key="2"/>,
        "The creatures seemed friendly, and the children were mesmerized by their bright colors and variety of features. “What shall we call them?”, asked one of the schoolchildren. “They're Xenos!” exclaimed another child.",<br key="3"/>,<br key="4"/>,
        "One of the Xenos walked up to the children and pulled out a small flute like device and blew into it. “He..llo!” it said, in a musical tone as the Xenos greeted them.  “This world, it is so wonderful. Please, can you show us more?” the Xenos said.",<br key="5"/>,<br key="6"/>,
        "The children also expressed their desire to learn more about the Xenos, their spaceships, and their advanced technologies, but realized they lacked the scientific instruments to study them. Undeterred, the children put out a call for support and in no time the school received donations of new tech, books, and science equipment. An exchange of ideas and technology was about to begin, and the children where excited about all this new knowledge they were about to learn.",<br key="7"/>,<br key="8"/>,
        "The Xenos project has arrived, and together we'll improve the lives of children all over the globe by leveraging the power of art, NFTs, and real-world funding for under-resourced schools."
      ],
      id: "details1"
    },
   {
    heading: "How and Where to Buy a Xenos",
    content: [
      "Xenos will be available during our smart contract launch and thereafter on OpenSea. At launch, you will be able to connect your wallet and mint a Xenos directly from this website. You'll want to be sure to mint your Xenos here before they surface on the secondary market.",<br  key="11"/>,<br  key="12"/>,
      "Every Xenos will be priced at a fixed 0.05 ETH. Joining our allow-sale list and minting a Xenos early will open the door to special community perks such as automatic inclusion in allow-lists for future drops. There will be a limit of 15 Xenos that can be purchased per transaction."
    ],
    id: "details2"
    },

    {
    heading: "Why Buy a Xenos?",
    content:[
      "When you buy a Xenos NFT, you'll join a community passionate about improving the lives of children all over the world, specifically by uplifting their schools through new scientific equipment, educational wings, or special projects particular to the needs of that school.",<br key="13"/>,<br key="14"/>,
      "25% of revenues from each milestone reached will be donated to our partner school (primary, middle, or high school).",<br key="15"/>,<br key="16"/>,
      "A percentage of the royalty of each secondary sale will also be gifted to the school.",<br key="17"/>,<br key="18"/>,
      "The foundational project team has parents and creators with international development and volunteering experience, including for international development initiatives such as Engineers Without Borders UK, and the New Zealand Aid Programme.",<br key="19"/>,<br key="20"/>,
      "Each school that we work with will be selected and governed by the Xenos team and wider community input. By joining our community, you'll have the power to shape and change children's lives for the better. You'll also have access to an avatar you'll be proud to display and call your own.",<br key="21"/>,<br key="22"/>,
      "Beyond new and exciting collections, members of the Xenos community will enjoy surprise air drops created with guest artists, a say through community voting on future schools to support, and being part of a great community. The sky's the limit!"
    ],
    id: "details3"
   },
  {
    heading: "What's next for Xenos?",
    content: [
      "Our community begins with an initial drop of 3,333 Xeno NFTs. But this is just the beginning. As each collection will support a specific school, we are in for the long haul as we continue to iterate new collections in support of children across the globe. We work directly with each school and do not rely on any third-party charity organizations. Each drop will focus on one school plus add more funds to the community wallet."
    ],
    id: "details4"
  },
    {
      heading: "Technical Specifications",
      content: [
        "Xenos is a collection of 3,333 unique NFTs and randomly generated from hand drawn traits.",<br key="23"/>,<br key="24"/>,
        "Each Xenos is an ERC-721A token on the Ethereum blockchain. Two guiding technical principles for the project are transparency and decentralization. Once sold-out, all the art will be stored off-chain on the decentralized IPFS network. A permanence hash is hard coded into the contract to validate art integrity",<br key="25"/>,<br key="26"/>,
        "The art generation will produce a combination of common, rare, and super rare traits, and the final art will only be assigned at the time of reveal, putting everyone - including the project developers - on an equal and level playing field.",<br key="72"/>,<br key="73"/>,
        "Verified contract: ",<a href="https://etherscan.io/address/0x892bdf6a7D60E76dBf1E2e7078908d360D5367fb#code" target="_blank" key="74" rel="noreferrer">0x892bdf6a7D60E76dBf1E2e7078908d360D5367fb</a>
      ],
      id: "details5"
    },
    {
      heading: "Fancy T&Cs",
      content: [
        "Xenos Project License Agreement",<br key="27"/>,<br key="28"/>,
        "This Xenos NFT License Agreement (the “License”) is a legally binding agreement entered into between the Xenos Project and you which comes into force when you acquire a Xenos NFT. When you acquire a Xenos NFT, you own all personal property rights to the token underlying the Xenos NFT (e.g., the right to freely sell, transfer, or otherwise dispose of that Xenos NFT), but you do not own the associated artwork, brand, or other intellectual property associated with that Xenos NFT, except for the specific licensed rights set forth below.",<br key="29"/>,<br key="30"/>,
        "Definitions",<br key="31"/>,<br key="32"/>,
        "“Xenos NFT” means any Xenos branded or themed NFT created by or on behalf of the Xenos Project",<br key="33"/>,
        "“Xenos NFT Art” means the artwork, image or works of authorship associated with a specific Xenos NFT.",<br key="34"/>,
        "“Xenos Trademarks” means any and all logos, trademarks, service marks, and trade dress associated with Xenos, Xenosproject.io, the Xenos NFTs, and the Xenos Project, including the “Xenos” or “Xenosproject.io” names, or any other names of Xenos related characters, products, or services developed by us.",<br key="35"/>,
        "“Xenos Project”, “us”, “we”, or “our” means the Xenos Project, Inc and/or its affiliates.",<br key="36"/>,
        "“Xenos Project Entities”, means the Xenos Project, its parents, affiliates, related companies, officers, directors, employees, agents, representatives, partners and licensors.",<br key="37"/>,
        "“Commercial Use” means any activity that is performed with the intent to generate revenue, such as sale or transfer of items (including NFTs) on any marketplace, creating and selling merchandise, derivative art, or other creative works.",<br key="38"/>,
        "“NFT” means any blockchain-tracked non-fungible token, including those complying with the ERC-721A, ERC-721, ERC-1155, or other non-fungible token standard.",<br key="39"/>,
        "“you” or “your” means a lawful owner of a Xenos NFT.",<br key="40"/>,<br key="41"/>,
        "License Grant",<br key="42"/>,<br key="43"/>,
        "Xenos NFT Art License. For as long as you lawfully own your Xenos NFT, and subject to your compliance with the terms of this License, we hereby grant you the following rights:",<br key="44"/>,
        "1.   A non-exclusive, worldwide, royalty-free, license to use, reproduce, display, modify, and create derivative works of the Xenos NFT Art for your Xenos NFT for personal, non-commercial use;",<br key="45"/>,
        "2.   A non-exclusive, worldwide, royalty-free, license to use, copy, reproduce and display the Xenos NFT Art for your Xenos NFT for any Commercial Use. This license permits you to mint and create new NFTs and NFT projects based on your Xenos NFT Art for Xenos NFTs that you own, as long as you don't use the Xenos Trademarks on, or to promote, such new NFTs. From time to time, we may collaborate with third parties to create Xenos NFTs which include artwork, images, works of authorship, logos, trademarks, service marks, or trade dress owned by a third party (“Third Party Content”). The license in this paragraph does not extend to any Xenos NFT or Xenos NFT Art that contains Third Party Content, and you may not use, copy, reproduce, display, create derivative works of, or create new NFTs based on such Third Party Content, or any portion thereof, for any Commercial Use, unless we or the applicable third parties expressly provide our consent in writing or by public announcement.",
        <br key="46"/>,<br key="47"/>,
        "Modifications and Derivative Works. We recognize that you might want to create modifications and derivative works of your Xenos NFT Art, and we allow you to do so under the scope of the licenses granted above. However, you acknowledge and agree that Xenos Project may also modify, create derivative works of, and update any Xenos NFT Art and may create works of authorship similar or identical to your own adaptations, derivative works, and modifications of any Xenos NFT Art. Accordingly, on behalf of yourself and your heirs, successors and assigns, you irrevocably covenant and agree not to assert or bring any suit, claim, demand or challenge against Xenos Project or its affiliates or licensees in connection with their use of any Xenos NFT Art or any adaptations, derivative works, and modifications thereto, even if such artwork or content is similar to or the same as any adaptations, derivative works, or modifications in any Xenos NFT Art that have been created by you.",
        <br key="48"/>,<br key="49"/>,
        "No Rights to Trademarks. Nothing in this License will be interpreted to grant you any rights to any Xenos Trademarks belonging to the Xenos Project. Without our written permission, you may not use any Xenos Trademarks for any Commercial Use, including to register any domain names or social media accounts using any Xenos Trademarks. This includes any Xenos Trademarks that may be displayed or contained in any Xenos NFT Art for your Xenos NFT. You may not remove, delete or obscure any trademark notice, copyright notice or other intellectual property notice in any Xenos NFT or Xenos NFT Art.",
        <br key="50"/>,<br key="51"/>,
        "Transfer. The licenses granted in this License are non-transferrable, except that if you sell or transfer your Xenos NFT, you will no longer be granted the foregoing licenses in the Xenos NFT Art, and such license will transfer to the new owner of the Xenos NFT associated with such Xenos NFT Art. In connection with any sales, transfers or similar transactions of the Xenos NFTs, the transferee agrees that by purchasing, accepting, or otherwise acquiring the Xenos NFT, they shall be deemed to accept the terms of this License. You may not transfer a Xenos NFT to a transferee that is located in a country that is subject to a U.S. government  or Canadian goverment embargo, or that has been designated by the U.S. government or Canadian government as a terrorist-supporting country; or is otherwise listed on any U.S. government or Canadian government list of prohibited or restricted parties.",
        <br key="52"/>,<br key="53"/>,
        "Restrictions and Reservation of Rights. Notwithstanding any of the above, you may not use the Xenos NFT Art in any way that constitutes unlawful, defamatory, discriminatory, harassing, abusive, fraudulent, racist, hateful, divisive, vulgar, cruel, illegal, or obscene activity, or that directly or indirectly promotes any such activity.",
        "All rights in and to the Xenos NFT Art and any other intellectual property of the Xenos Project not expressly licensed herein, including all moral rights, are hereby reserved by the Xenos Project. All goodwill arising from any use of the Xenos Trademarks will inure solely to the Xenos Project and its affiliates.",
        <br key="54"/>,<br key="55"/>,
        "Disclaimers. Your access to and use of the Xenos NFT and Xenos NFT Art is at your own risk. You understand and agree that the Xenos NFTs and Xenos NFT Art are provided to you on an “as is” and “as available” basis. Without limiting the foregoing, to the maximum extent permitted under applicable law, the Xenos Project, its parents, affiliates, related companies, officers, directors, employees, agents, representatives, partners and licensors, the “Xenos Project Entities”, disclaim all warranties and conditions, whether express or implied, of merchantability, fitness for a particular purpose or non-infringement. The Xenos Project Entities make no warranty or representation and disclaim all responsibility and liability for a - the completeness, accuracy, availability, timeliness, originality, security or reliability of the Xenos NFTs and Xenos NFT Art",
        "b - the operation or compatibility with any other application or any particular system, device, blockchain, digital wallet, hardware or marketplace, and c - whether the Xenos NFTs and Xenos NFT Art will meet your requirements or be available on an uninterrupted, secure or error-free basis, and d - the deletion of, or the failure to store or transmit the Xenos NFTs and Xenos NFT Art. The Xenos NFTs are intended for consumer enjoyment, use and consumption only. They are not a “security” as defined under the securities laws of every Canadian province.",
        <br key="56"/>,<br key="57"/>,
        "Limitations of Liability. To the extent not prohibited by law, you agree that in no event will the Xenos Project Entities be liable (a) for any indirect, special, exemplary, incidental, consequential or punitive damages (including, but not limited to, procurement of substitute goods or services, loss of use, theft of assets, data, or profits, business interruption or any other damages or losses, arising out of or related to your use or inability to use the services), however caused and under any theory of liability, whether under this license or otherwise arising in any way in connection with the services or this license and whether in contract, strict liability or tort (including negligence or otherwise) even if the Xenos Project Entities have been advised of the possibility of such damage,",
        "or (b) for any other claim, demand or damages whatsoever resulting from or arising out of or in connection with this license or the delivery, use or performance of the services. The maximum aggregate liability of the Xenos Project Entities for all damages and causes of action, whether in contract, tort (including negligence) or otherwise, shall be the greater of (i) $1,000 or (ii) the amount you paid the Xenos Project for your Xenos NFT(s).",
        <br key="58"/>,<br key="59"/>,
        "Indemnification. By entering into this License and accessing or using the Xenos NFTs or Xenos NFT Art, you agree that you shall defend, indemnify and hold the Xenos Project Entities harmless from and against any and all claims, costs, damages, losses, liabilities and expenses (including attorneys' fees and costs) incurred by the Xenos Project Entities arising out of or in connection with: (i) your violation or breach of any term of this License or any applicable law or regulation; (ii) your violation of any rights of any third party; (iii) your access to or use of the Xenos NFT or Xenos NFT Art; (iv) any modifications to or derivative works of the Xenos NFT Art you create, or (v) any fraud, negligence or wilful misconduct committed by you.",
        <br key="60"/>,<br key="61"/>,
        "Additional Features. Xenos Project may choose to make additional features, access, content, items or other benefits available to owners of Xenos NFTs (“Additional Features”). Xenos Project has no duty or obligation to provide you with any Additional Features, and you should not expect any Additional Features when acquiring a Xenos NFT. Additional Features may be subject to additional terms and conditions, which may be presented to you at the time they are made available. This license allows us to add more features to your Xenos NFT, but doesn't create an obligation for us to do so.",
        <br key="62"/>,<br key="63"/>,
        "Updating This License. We may modify this License from time to time. If we make changes that are material, we will use reasonable efforts to attempt to notify you, such as by placing a prominent notice on the first page of our website. However, it is your sole responsibility to review this License from time to time to view any such changes. Your continued access or use of the Xenos NFTs or Xenos NFT Art after the License has been updated will be deemed your acceptance of the modified License.",
        <br key="64"/>,<br key="65"/>,
        "Termination of License. If you breach any of the provisions of this License, all licenses granted by the Xenos Project will terminate automatically. Upon the termination of your licenses, you shall cease all marketing, distribution, or sale of goods, services and media that feature the Xenos NFT Art and shall cease all further use of the Xenos NFT Art. All provisions which by their nature should survive the termination of this License shall continue in full force and effect subsequent to and notwithstanding any termination of this License by Xenos Project or you. Termination will not limit any of Xenos Project' other rights or remedies at law or in equity.",
        <br key="66"/>,<br key="67"/>,
        "Governing Law. This License is governed by the laws of Canada and the Province of Ontario without regard to conflict of laws rules, and the proper venue for any disputes arising out of or relating to any of the same will be the federal and provincial courts located in Toronto, Ontario or Ottawa, Ontario. EACH PARTY HEREBY EXPRESSLY WAIVES ANY RIGHT TO A JURY TRIAL IN ANY ACTION OR PROCEEDING BROUGHT BY OR AGAINST EITHER PARTY IN CONNECTION WITH THIS LICENSE. You and the Xenos Project agree that the United Nations Convention on Contracts for the International Sale of Goods will not apply to the interpretation or construction of this License.",
        <br key="68"/>,<br key="69"/>,
        "Miscellaneous. If any provision of this License shall be unlawful, void or for any reason unenforceable, then that provision shall be deemed severable from this License and shall not affect the validity and enforceability of any remaining provisions. This License and the licenses granted hereunder may be freely assigned by the Xenos Project but may not be assigned by you without the prior express written consent of the Xenos Project. Any purported assignment in violation of this License will be null and void. No waiver by either party of any breach or default hereunder shall be deemed to be a waiver of any preceding or subsequent breach or default. You represent and warrant that you have the legal capacity to agree to this License.",
        <br key="70"/>,<br key="71"/>,
        "Address for Service. The Xenos Project address for service is 2345 Yonge St, suite 910, Toronto Ontario M4P 2E5, Canada.",
      ],
      id: "details6"
    }
  ];