export const Hero = (props) => {
    return (
        <div id='hero'>
          <div className='container hero-container'>
            <div className='row'>
            {/* <div className='col-md-8 col-md-offset-2 intro-text'> */}
                <div className='col-md-12 hero-banner'>
                    <img src="img/banner.png" className="hero-img" alt="" />
                </div>
            </div>
          </div>
        </div>
        );
    };
    