import React, { useState } from "react";
import { Fade } from "react-awesome-reveal";

export const XenosDetails = ({ heading, content, id }) => {
    const [isActive, setIsActive] = useState(false);
    return (
      <li className="accordion-item" key={id}>
        <div className="accordion-toggle" id={id} onClick={() => setIsActive(!isActive)}>
          <h3>{heading}</h3><span>{isActive ? "-" : "+"}</span>
        </div>
        {isActive && <Fade><p>{content}</p></Fade>}
        {/*{isActive && <div className="accordion-content">{content}</div>}*/}
      </li>
    );
  };
  

