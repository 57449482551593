import { useState, useEffect } from "react";
import useWeb3Modal from "./useWeb3Modal";
import { ethers } from "ethers";
import ContractData from "../data/contract.json";

//import { MerkleTree } from "merkletreejs";
//import keccak256 from "keccak256";

//import ConfettiExplosion from '@reonomy/react-confetti-explosion';

//const whitelistAddresses = [
//    "0x06130D7682e30d70f1C13A16028EAf8058c16A97"
//];
  
//const leafNodes = whitelistAddresses.map(addr => keccak256(addr));
//const merkleTree = new MerkleTree(leafNodes, keccak256, { sortPairs: true });
//roothash
//console.log('0x' + merkleTree.getRoot().toString('hex'));

//function GetMerkleHash(address) { 
//  let walletAddress = keccak256(address);
//  return (merkleTree.getHexProof(walletAddress));
//}

const MintXenos = async (provider, address, setErrorMessage, processing, setProcessing, mintNumber, successfulMint, setSuccessfulMint) => {
  //let hexProof = GetMerkleHash(address);
  setErrorMessage("");
  setSuccessfulMint(false);
    //Web3Modal send transaction commands
    if (processing === true) {
      return
    } 
    try {
      setProcessing(true);
      //this is the signer - it needs to be tied in with proper fetch accounts
      const signer = await provider.getSigner();
      const contract = await new ethers.Contract(ContractData.contractAddress, ContractData.abi, signer);
      let price = mintNumber * process.env.REACT_APP_COST;
      try {
        const tx = await contract.mint(
          mintNumber,
          {value: ethers.utils.parseEther(String(price))}
        );
        //const tx = await contract.whitelistMint(
        //  mintNumber,
        //  hexProof,
        //  {value: ethers.utils.parseEther(String(price))}
        //);
        const receipt = await tx.wait(1);
        console.log(receipt);
        setErrorMessage("Congrats you minted Xenos!");
        setSuccessfulMint(true);
        setProcessing(false);
      } catch (err) {
        console.error("Error with mint", err);
        setErrorMessage("Something went wrong");
        setSuccessfulMint(false);
        setProcessing(false);
      } 
    } catch (err) {
      console.error("Error with provider or contract", err);
      setErrorMessage("Something went wrong");
      setSuccessfulMint(false);
      setProcessing(false);
    }
};

function MintButton(props) { 

  return (
    <button
      className='btn btn-custom-mint btn-lg page-scroll'
      onClick={() => {
        MintXenos(props.provider, props.address, props.setErrorMessage, props.processing, props.setProcessing, props.mintNumber, props.successfulMint, props.setSuccessfulMint)
      }}
     >       
      {!props.processing ? 
        "Mint"
      :
        "Processing"
      }
      {/*{props.successfulMint ?
        <ConfettiExplosion 
          force={0.5}
          duration={3000}
          particleSize={6}
          particleCount={120}
          floorHeight={800}
          floorWidth={1000}
        />
      :
        ""
      }*/}
    </button>
  );
}

function WalletButton(props) {  

  useEffect(() => {
    async function fetchAccount() {
      try {
        if (!props.provider) {
          props.setConnected(false);
          return;
        }
        // Load the user's accounts.
        const accounts = await props.provider.listAccounts();
        props.setAccount(accounts[0]);
        const network = await props.provider.getNetwork();
        props.setNetworkId(network.chainId);
        if (Number(process.env.REACT_APP_NETWORK_ID) === props.networkId) {
          props.setRendered(props.account.substring(0, 6) + "..." + props.account.substring(36));
          props.setConnected(true);
        } else {
          props.setRendered("Please connect with Ethereum wallet");
          props.setConnected(false);
        }
      } catch (err) {
        props.setAccount("");
        props.setConnected(false);
        props.setRendered("Issue connecting with wallet");
        console.error(err);
      }
    }
    fetchAccount();
  });

  return (
    <button
      className='btn btn-custom btn-lg page-scroll'
      id='connect-button'
      onClick={() => {
        if (!props.provider) {
          props.loadWeb3Modal();
        } else {
          props.logoutOfWeb3Modal();
         }
      }}
     >
        {props.rendered === "" && "Connect Wallet to Mint"}
        {props.rendered !== "" && props.rendered}
     </button>
  );
}

export const Header = (props) => {
  const [connected, setConnected] = useState(false);
  const [mintNumber, setMintNumber] = useState(1);

  const [provider, loadWeb3Modal, logoutOfWeb3Modal] = useWeb3Modal();
  const [account, setAccount] = useState("");
  const [rendered, setRendered] = useState("");
  const [networkId, setNetworkId] = useState(0);
  const [processing, setProcessing] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successfulMint, setSuccessfulMint] = useState(false);

  function incrementMintAmount() {
    setSuccessfulMint(false);
    setMintNumber(Math.min(15, mintNumber + 1));
  }
  
  function decrementMintAmount() {
    setSuccessfulMint(false);
    setMintNumber(Math.max(1, mintNumber - 1));
  }
  
  return (
    <header id='header'>
      <div className='intro'>
        <div className='overlay'>
          <div className='container'>
          <h2>Mint</h2>
            <div className='row'>
              {/* <div className='col-md-8 col-md-offset-2 intro-text'> */}
              <div className='col-md-12 intro-text'>
                <div className='col-md-12 intro-button'>  
                   <WalletButton 
                      setConnected={setConnected} 
                      provider={provider}
                      loadWeb3Modal={loadWeb3Modal}
                      logoutOfWeb3Modal={logoutOfWeb3Modal}
                      account={account}
                      setAccount={setAccount}
                      rendered={rendered}
                      setRendered={setRendered}
                      networkId={networkId}
                      setNetworkId={setNetworkId}
                   />
                   {connected ? 
                      <div id='mintbutton' className='mint-number'>
                        <button className="btn btn-custom decrease" onClick={() => decrementMintAmount()}>-</button>
                        {mintNumber}
                        <button className="btn btn-custom increase" onClick={() => incrementMintAmount()}>+</button>
                        <br />
                        <MintButton 
                          provider={provider}
                          address = {account}
                          errorMessage = {errorMessage}
                          setErrorMessage = {setErrorMessage}
                          processing = {processing}
                          setProcessing = {setProcessing}
                          mintNumber = {mintNumber}
                          successfulMint = {successfulMint}
                          setSuccessfulMint = {setSuccessfulMint}
                        />
                        <p>{errorMessage}</p>
                      </div>
                   : ""}
                {/*<a 
                   href='https://discord.gg/Q2TxRsmakb'
                   target="_blank" 
                   rel="noreferrer"
                   className='btn btn-custom btn-lg page-scroll'
                >
                   JOIN THE DISCORD
                </a>*/}
               </div>
                {/*<p>{props.data ? props.data.paragraph : 'Loading'}</p>{' '} */}
              </div>
            </div>
          <div className='row'> 
            <div className='col-md-12 intro-info'>
              <h1>
                  {props.data ? props.data.paragraph2 : 'Loading'}
                  <span></span>
              </h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
  )
}
