export const XenosStory = (props) => {
  return (
    <div id='story' className='text-center'>
      <div className="container">
           <div className="about-text">
              <h2>Mission</h2>
              {props.data
                      ? props.data.paragraph.map((d, i) => (
                          <p key={`${d}-${i}`}> {d}</p>
                        ))
                      : "loading"}
           </div>
       </div>
    </div>
  );
};
