export const XenosSchool = (props) => {
  return (
    <div id='school' className='text-center'>
      <div className="container">
           <div className="about-text">
              <h2>Our First School</h2>
              {props.data
                      ? props.data.paragraph.map((d, i) => (
                          <p key={`${d}-${i}`}> {d}</p>
                        ))
                      : "loading"}
                      <a href={props.data ? props.data.school_link : '/'}  target="_blank" rel="noreferrer">St Paul's KAASO Uganda</a>
           </div>
       </div>
    </div>
  );
};
