import { useState, useEffect } from "react";
import { Navigation } from "./components/navigation";
import { Header } from "./components/header";
import { Team } from "./components/Team";
import { Contact } from "./components/contact";
import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
import "./App.css";
import { XenosStory } from "./components/xenos_story1";
import { XenosSchool } from "./components/xenos_school";
import { XenosImages } from "./components/xenos_images";
import { Timeline } from "./components/timeline";
import { Hero } from "./components/hero";
import { XenosDetails } from "./components/xenos_details";
import { accordionData } from "./data/accordiandata";
import Particles from "react-tsparticles"; 
import ParticleData from "./data/particles.json";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const particlesInit = (tsParticles) => { 
  console.log(tsParticles);
  // you can initialize the tsParticles instance (main) here, adding custom shapes or presets
};

const particlesLoaded = (container) => {
  console.log(container);
};

const App = () => {
  const [landingPageData, setLandingPageData] = useState({});

  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  return (
    <div>
      <Navigation />
      <Hero daya={landingPageData.Hero} />
      <Header data={landingPageData.Header} />
      <XenosStory data={landingPageData.About} />
      <XenosImages data={landingPageData.XenosImages} />
      <XenosSchool data={landingPageData.School} />
      <div id='details' className='text-center'>
         <div className="container">
           <div className="col-md-10 col-md-offset-1 about-text">
              <h2>F.A.Q</h2>
              <ul className="accordion">
                  {accordionData.map((props, i) => (
                     <XenosDetails key={i} heading={props.heading} content={props.content} id={props.id} />
                  ))}
              </ul>
           </div>
         </div>
       </div>
      <Timeline data={landingPageData.Timeline} />
      <Team data={landingPageData.Team} />
      <Contact data={landingPageData.Contact} />
      <Particles
      id = "tsparticles"
      options = {ParticleData}
      className = "particles"
      init = {particlesInit}
      loaded = {particlesLoaded}
    />
    </div>
  );
};

export default App;
